<template>
  <div class="px-3">
    <h4 class="text-center my-3">Catálogo de lojas</h4>

    <div class="row mb-2" v-for="loja in lojas" :key="loja.identificador">
      <div class="col align-self-center">
        <router-link
          replace
          class="link-loja"
          :to="{
            name: 'loja',
            params: { id: loja.identificador },
          }"
        >
          {{ loja.nome }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { useHead } from "@vueuse/head";
import { useApi } from "@/composables/useApi";

export default {
  name: "Loja",
  components: {},
  setup() {
    useHead({
      title: computed(() => "Loja MEI Grátis"),
      link: [
        {
          id: "favicon",
          rel: "icon",
          href: computed(() => "/favicon.png"),
        },
      ],
    });

    return reactive({
      lojas: [],
    });
  },
  async beforeRouteEnter(origem, destino, proximo) {
    const { obtemLojas } = useApi();
    const lojas = obtemLojas();

    if (lojas.length > 0) {
      proximo((vm) => {
        vm.lojas = lojas;
      });
    } else {
      proximo({ name: "loja-desconhecida" });
    }
  },
};
</script>

<style scoped>
.link-loja {
  font-size: 1.1em;
  color: #212529;
}
.logo-loja {
  width: 50px;
  height: 50px;
}
</style>